import React, { useState, useContext, useEffect, useCallback } from "react";
import { onSnapshot, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import axios from "axios";
import { saveAs } from 'file-saver';

import { AuthContext } from "../../context/AuthProvider";
import { StoreContext } from "../../context/StoreProvider";
import UserSidebar from "../SidebarComponents/UserSidebar";
import PlusActions from "../HelperComponents/PlusActions";
import PageDropdown from "../PageUtilComponents/PageDropdown";
import PageUpdate from "../PageUtilComponents/PageUpdate";
import QRSidebar from "../SidebarComponents/QRSidebar";
import ScreenLoader from "../HelperComponents/ScreenLoader";
import ReactDOM from "react-dom/client";
import PageView from "../PageUtilComponents/PageView";
import PopupToast from "../../components/HelperComponents/PopupToast";

import { ReactComponent as DownloadLogo } from "../../assets/download.svg";
import { ReactComponent as UsersLogo } from "../../assets/people.svg";
import { ReactComponent as QRLogo } from "../../assets/qr_code.svg";
import { ReactComponent as PipIcon } from "../../assets/pip_icon.svg";
import pause_logo from "../../assets/pause.svg";
import play_logo from "../../assets/play.svg";
import min_fs_logo from "../../assets/minimize_fs.svg";
import max_fs_logo from "../../assets/maximize_fs.svg";

const pipNotSupported = 'Your browser does\'nt support PIP at present.';

const sanitizeDashFileName = (name) => {
  if (typeof name !== 'string') {
    console.error('Invalid filename provided:', name);
    return 'Whitepad-session';
  }
  return name.replace(/[^a-zA-Z0-9-_]/g, '-');
};

const PresenterBoard = ({ sessionData }) => {

  const { user } = useContext(AuthContext);
  const { handlePDFDownload } = useContext(StoreContext);
  const [showLoader, setShowLoader] = useState(true);
  const [isPause, setIsPause] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const [imgHeight, setImgHeight] = useState('75vh');
  const [isUserSBOpen, setUserSBOpen] = useState(false);
  const [pageId, setPageId] = useState(0);
  const [pagesData, setPagesData] = useState([]);
  const [ddUpdateFlag, setDDUpdateFlag] = useState(true);
  const [isQRCodeOpen, setQRCodeOpen] = useState(false);
  const [showPT, setShowPT] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [infoMsg, setInfoMsg] = useState('');
  const handle = useFullScreenHandle();

  const HandlePendingUserSidebarData = (numberOfUsers) => {
    setNotificationCount(numberOfUsers)
  };

  const openWindow = async () => {
    if ('documentPictureInPicture' in window) {
      try {
        const dpip = await window.documentPictureInPicture.requestWindow({
          width: "350",
          height: "600",
        });
        const pipDiv = dpip.document.createElement("div");
        pipDiv.setAttribute("id", "pip-root");
        dpip.document.body.append(pipDiv);
        const pipRoot = ReactDOM.createRoot(
          dpip.document.getElementById("pip-root")
        );

        pipRoot.render(<PageView imgUrl={imgUrl} isPause={isPause} canFS={false} pipMode={true} />);

        // Function to update the pip dynamically
        const updatePip = (newUrl, updatePause) => {
          pipRoot.render(
            <PageView imgUrl={newUrl} isPause={updatePause} canFS={false} pipMode={true} />
          );
        };

        document.addEventListener("updatePip", (event) => {
          if (event.detail) {
            updatePip(event.detail.newUrl, event.detail.updatePause);
          }
        });

        //copying css
        [...document.styleSheets].forEach((styleSheet) => {
          try {
            const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
            const style = document.createElement('style');

            style.textContent = cssRules;
            dpip.document.head.appendChild(style);
          } catch (e) {
            const link = document.createElement('link');

            link.rel = 'stylesheet';
            link.type = styleSheet.type;
            link.media = styleSheet.media;
            link.href = styleSheet.href;
            dpip.document.head.appendChild(link);
          }
        });

      } catch (error) {
        if (error) {
          console.error(error);
        }
      }
    } else {
      setInfoMsg(pipNotSupported)
      setShowPT(true)
    }
  };

  const togglePause = () => {
    const sessionRef = doc(db, 'board_session', sessionData.sess_id);
    setDoc(sessionRef, { pause: !isPause }, { merge: true });
    setIsPause(!isPause)
  };

  const fullscreenToggle = () => {
    if (handle.active) {
      handle.exit();
      setImgHeight('75vh')
    } else {
      handle.enter();
      setImgHeight('100vh')
    }
  };

  const ToggleUserSidebar = () => {
    setQRCodeOpen(false);
    isUserSBOpen === true ? setUserSBOpen(false) : setUserSBOpen(true);
  }

  const ToggleQRSidebar = () => {
    setUserSBOpen(false);
    isQRCodeOpen === true ? setQRCodeOpen(false) : setQRCodeOpen(true);
  }

  const generatePDF = async () => {
    setShowLoader(true);
    const response = await handlePDFDownload(sessionData.sess_id, imgUrl);
    if (response.success) {
      saveAs(response.data, sanitizeDashFileName(sessionData.session_name));
      setShowLoader(false);
    } else {
      setShowLoader(false)
    }
  };

  // plus actions
  const actions = [
    {
      label: "Generate PDF",
      icon: <DownloadLogo className="plusActionBtn" />,
      onClick: generatePDF
    },
    {
      label: "Toggle Fullscreen",
      icon: <img src={handle.active ? min_fs_logo : max_fs_logo} className="plusActionBtn" alt={handle.active ? `minimize` : `maximize`} />,
      onClick: fullscreenToggle
    },
    {
      label: "Toggle Play/Pause",
      icon: <img src={isPause ? play_logo : pause_logo} className="plusActionBtn" alt={isPause ? `play` : `pause`} />,
      onClick: togglePause
    },
    {
      label: "Toggle User List",
      icon: <UsersLogo className="plusActionBtn" />,
      onClick: ToggleUserSidebar,
      count: notificationCount
    },
    {
      label: "Toggle Details",
      icon: <QRLogo className="plusActionBtn" />,
      onClick: ToggleQRSidebar
    },
    {
      label: "Toggle PIP",
      icon: <PipIcon className="plusActionBtn" />,
      onClick: openWindow
    }
  ];

  const updatePage = useCallback(
    (page_id, page_src) => {
      setPageId(page_id);

      const docRef = doc(db, "board_session", sessionData.sess_id);
      updateDoc(docRef, {
        "page_id": page_id,
        "page_src": page_src
      });
    },
    [sessionData]
  );

  const handleDDSelector = (option) => {
    if (option.id === 0) {
      updatePage(0, '');
    } else {
      updatePage(option.id, option.page_data);
    }
  };

  useEffect(() => {
    if (user && ddUpdateFlag) {
      const getPagesData = () => {
        axios({
          method: 'get',
          baseURL: process.env.REACT_APP_API_BASE_URL,
          url: '/get-page-data',
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
          },
          params: {
            sess_id: sessionData.sess_id
          }
        }).then(function (response) {
          let resp_data = response.data
          setPagesData(resp_data.data);
          setDDUpdateFlag(false);
          updatePage(0, '');
        }).catch((error) => {
          if (error.response) {
            console.error('Server Error:', error.response.status);
          } else if (error.request) {
            console.error('Network Error:', error.request);
          } else {
            console.error('Error:', error.message);
          }
        });
      };

      return getPagesData();
    }
  }, [sessionData, user, ddUpdateFlag, updatePage]);

  useEffect(() => {

    if (user) {
      let firstFlag = true;
      const unsub = onSnapshot(doc(db, "board_session", sessionData.sess_id), { source: 'server' }, (data) => {
        try {
          if (data.exists) {
            const temp = [data.data()];

            setIsPause(temp[0].pause);
            setPageId(temp[0].page_id);

            if (temp[0].page_id === 0) {
              if (!isPause || firstFlag) {
                setImgUrl(temp[0].data_base64);
                document.dispatchEvent(
                  new CustomEvent("updatePip", { detail: { newUrl: imgUrl, updatePause: isPause } })
                );
                firstFlag = false;
              }
            } else {
              setImgUrl(temp[0].page_src);
              setIsPause(false)
              document.dispatchEvent(
                new CustomEvent("updatePip", { detail: { newUrl: imgUrl, updatePause: isPause } })
              );
            }
          }
        } catch (error) {
          console.error('Error handling snapshot data:', error);
        }
      },
        (error) => {
          console.error('Snapshot listener error:', error);
        });

      return () => unsub();
    }

  }, [sessionData, isPause, user, imgUrl]);

  return (showLoader ?
    <>
      <ScreenLoader placeMiddle={true} />
      {(() => {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
        return null;
      })()}
    </>
    :
    <>
      {user &&
        <div className="p5 m-auto d-flex flex-column align-items-center">
          <div className={`session-card ${imgUrl ? 'mw-100' : ''}`}>

            {user &&
              <>
              <UserSidebar docId={sessionData.sess_id} isUserSBOpen={isUserSBOpen} ToggleUserSidebar={ToggleUserSidebar} HandlePendingUserSidebarData={HandlePendingUserSidebarData}></UserSidebar>
                <QRSidebar docId={sessionData.sess_id} session_pin={sessionData.session_pin} isQRCodeOpen={isQRCodeOpen} ToggleQRSidebar={ToggleQRSidebar}></QRSidebar>
              </>
            }

            <div className="header-bar container">
              <span>{sessionData.session_name}</span>
              {user &&
                <>
                  {pagesData.length > 1 &&
                    <PageDropdown options={pagesData} ddSelector={handleDDSelector} pageId={pageId}></PageDropdown>
                  }
                </>
              }
            </div>

            <FullScreen handle={handle}>
              <PlusActions actions={actions} />
              {imgUrl &&
                <div className="container">
                  <div className="row">
                    <div className="col-12 position-relative">
                      {isPause &&
                        <div className="error-toast error-toast-bottom error-toast-light">
                          <p>Broadcast paused by the caster!</p>
                        </div>
                      }
                      <img src={imgUrl} style={{ height: `${imgHeight}`, opacity: (isPause ? 0.5 : 1), transition: "all 0.25s linear 0s" }} alt="" className="w-100" />
                    </div>
                    {user &&
                      <PageUpdate
                        type={pageId === 0 ? 'save' : 'delete'}
                        pageUrl={imgUrl}
                        docId={sessionData.sess_id}
                        pageId={pageId}
                        setDDUpdateFlag={setDDUpdateFlag}
                      />
                    }
                  </div>
                </div>
              }
            </ FullScreen>
          </div>
        </div>
      }
       <PopupToast showPT={showPT} setShowPT={setShowPT} infoMsg={infoMsg} btnMsg='OK'/>
    </>
  );
}

export default PresenterBoard;
