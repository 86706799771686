import React, { useContext } from 'react';
import QRCode from 'qrcode.react';
import { StoreContext } from '../context/StoreProvider';
import copy_url_logo from "../assets/copy_url.svg";

const DownloadApp = () => {

    const { siteConfig } = useContext(StoreContext);

    const size = 300;
    const bgColor = "#eee";
    const fgColor = "#5f1587";
    const appLink = siteConfig?.MOBILE_APP_LINK;

    const CopyToClipboard = (elementId) => {
        var text = document.getElementById(elementId).innerHTML;
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <section className="pageModal downloadapp-section">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12 pt-3">
                            <h1>Download App</h1>
                            <p className="p-url">
                                <span>You can download our Android app by scaning the below QR code {siteConfig && `or hitting this link`}  : </span>
                                <br />
                                {siteConfig &&
                                    <>
                                        <a id='app-link-id' className="links-clr" href={appLink} target="_blank" rel="noreferrer">{appLink}</a>
                                        <span onClick={() => CopyToClipboard('app-link-id')}>
                                            <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy app link" />
                                        </span>
                                    </>
                                }
                            </p>

                            <QRCode
                                style={{
                                    backgroundColor: 'white',
                                }}
                                className='mt-3 p-3'
                                size={size}
                                level='M'
                                includeMargin={false}
                                renderAs="svg"
                                bgColor={bgColor}
                                fgColor={fgColor}
                                value={appLink}
                            />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DownloadApp;