import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTable } from "react-table";
import { Button, Modal } from "react-bootstrap";
import axios from 'axios';
import ScreenLoader from "../HelperComponents/ScreenLoader";
import { ReactComponent as EditLogo } from '../../assets/edit.svg';
import { ReactComponent as DeleteLogo } from '../../assets/delete.svg';

const GroupUsersForm = ({ user, group_id, onBack }) => {

	const [groupName, setGroupName] = useState('');
	const [groupData, setGroupData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [editingValue, setEditingValue] = useState({});
	const [selectedId, setSelectedId] = useState(null);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteModelClose = () => setShowDeleteModal(false);
	const handleDeleteModelShow = () => setShowDeleteModal(true);

	const [showGroupStatusModal, setShowGroupStatusModal] = useState(false);
	const handleGroupStatusModelClose = () => setShowGroupStatusModal(false);
	const handleGroupStatusModelShow = () => setShowGroupStatusModal(true);

	const handleDeletePopup = useCallback((id) => {
		handleDeleteModelShow();
		setSelectedId(id);
	}, []);

	const handleStatusChangePopup = useCallback((id) => {
		handleGroupStatusModelShow();
		setSelectedId(id);
	}, []);

	const [showNewGroupUserModal, setShowNewGroupUserModal] = useState(false);
	const handleNewGroupUserModelClose = () => setShowNewGroupUserModal(false);
	const handleNewGroupUserModelShow = useCallback((id) => {
		if (id) {
			const currentRow = groupData.find((row) => row.id === id);
			if (!currentRow) {
				console.error("Error finding group user, " + id + " not found");
			} else {
				setEditingValue({ "id": id, "full_name": currentRow.full_name, "email": currentRow.email });
			}
		} else {
			setEditingValue({});
		}
		setShowNewGroupUserModal(true);
	}, [groupData]);

	const updateGroupUser = useCallback(async (user_id, user_full_name, user_email, user_status) => {

		const type = (user_status === 2) ? 'delete_user' : 'update_user';
		try {
			const response = await axios({
				method: 'post',
				baseURL: process.env.REACT_APP_API_BASE_URL,
				url: '/update-group-user',
				auth: {
					username: process.env.REACT_APP_API_USERNAME,
					password: process.env.REACT_APP_API_PASSWORD
				},
				params: {
					db_id: user.db_id,
					group_id: group_id,
					type: type,
					user_id: user_id,
					user_email: user_email,
					user_fullname: user_full_name,
					user_status: user_status,
				}
			});

			const respData = response.data;
			if (respData.status === 1) {
				return respData.data;
			} else {
				return false;
			}
		} catch (error) {
			console.error('Error updating the group user:', error);
			return false;
		}
	}, [user, group_id]);


	const handleToggleActive = useCallback(async (id) => {
		const currentRow = groupData.find((row) => row.id === id);
		if (!currentRow) {
			console.error("Error updating group user, not found");
			return;
		}
		const updatedStatus = currentRow.status === 1 ? 0 : 1;

		const response = await updateGroupUser(id, currentRow.full_name, currentRow.email, updatedStatus)

		if (response) {
			setGroupData((rows) =>
				rows.map((row) => (row.id === id ? { ...row, status: updatedStatus } : row))
			);
			handleGroupStatusModelClose();
		} else {
			console.error('Error updating the group user status');
			alert('Error updating the group user status');
			handleGroupStatusModelClose();
		}
	}, [groupData, updateGroupUser]);

	const handleDelete = useCallback(async (id) => {
		const currentRow = groupData.find((row) => row.id === id);
		if (!currentRow) {
			console.error("Error deleting group user, " + id + " not found");
			return;
		}

		const response = await updateGroupUser(id, currentRow.full_name, currentRow.email, 2)

		if (response) {
			setGroupData((rows) => rows.filter((row) => row.id !== id));
			handleDeleteModelClose();
		} else {
			console.error('Error deleting the group user');
			alert('Error deleting the group user');
			handleDeleteModelClose();
		}
	}, [groupData, updateGroupUser]);

	const handleUserForm = async (e) => {
		e.preventDefault();
		const id = editingValue.id ? editingValue.id : 0;
		const name = e.target.name.value.trim();
		const email = e.target.email.value.trim();

		// Regular expression for validating email
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

		// Email validation checks
		if (!email) {
			console.error("Email is required");
			return;
		}

		if (!emailRegex.test(email)) {
			console.error("Please enter a valid email address.");
			return;
		}

		// Check for duplicate email
		const isDuplicateEmail = groupData.some((user) => user.email.toLowerCase() === email.toLowerCase() && user.id !== id);
		if (isDuplicateEmail) {
			console.error("Email already exists.");
			//display a toast notification here
			handleNewGroupUserModelClose();
			return false;
		}

		try {
			const response = await updateGroupUser(id, name, email, 1);

			if (response) {
				// Handle the response: either update existing or add new row
				if (id !== 0) {
					// For updating existing user
					setGroupData((rows) =>
						rows.map((row) => {
							if (row.id === id) {
								return { ...row, full_name: name, email: email };  // Update full_name and email
							}
							return row;
						})
					);
				} else {
					// For adding a new user
					setGroupData((prevRows) => [
						...prevRows,
						{ id: response, full_name: name, email: email, status: 1 }
					]);
				}

				handleNewGroupUserModelClose();
				return true;
			} else {
				console.error('Error updating the group user');
				handleNewGroupUserModelClose();
				return false;
			}
		} catch (error) {
			console.error('Error occurred during group user update:', error);
			handleNewGroupUserModelClose();
			return false;
		}
	};

	useEffect(() => {
		if (group_id !== 'new') {
			const fetchGroupsData = async () => {
				setLoading(true);
				try {
					const response = await axios({
						method: 'get',
						baseURL: process.env.REACT_APP_API_BASE_URL,
						url: '/get-group-data',
						auth: {
							username: process.env.REACT_APP_API_USERNAME,
							password: process.env.REACT_APP_API_PASSWORD
						},
						params: {
							db_id: user.db_id,
							group_id: group_id,
						}
					});
					const data = response.data.data;
					setGroupData(data.group_users || []);
					setGroupName(data.group_name);
				} catch (error) {
					console.error('Error fetching group data:', error);
				} finally {
					setLoading(false);
				}
			};
			fetchGroupsData();
		} else {
			setGroupName('New Group');
			setLoading(false);
		}
	}, [group_id, user]);

	const columns = useMemo(() => [
		{
			Header: "User Name",
			accessor: "full_name",
			Cell: ({ value }) => <div>{value}</div>,
		},
		{
			Header: "Email",
			accessor: "email",
			Cell: ({ value }) => <div>{value}</div>,
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: ({ row }) => (
				<div
					className={`status-dot  ${(row.original.status === 1) ? "active" : "inactive"}`}
					onClick={() => handleStatusChangePopup(row.original.id)}
				>
				</div>
			),
		},
		{
			Header: "Actions",
			Cell: ({ row }) => (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<button className="btn edit-btn" onClick={() => handleNewGroupUserModelShow(row.original.id)}>
						<EditLogo className='svg-clr-neutral-light' />
					</button>
					<button className="btn delete-btn" onClick={() => handleDeletePopup(row.original.id)}>
						<DeleteLogo className='svg-clr-neutral-alert-dark' />
					</button>
				</div>
			),
		},
	], [handleStatusChangePopup, handleDeletePopup, handleNewGroupUserModelShow]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data: groupData });

	if (loading) {
		return <ScreenLoader placeMiddle={true} />;
	}

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="mb-3">You are currently editing group: {groupName}</div>
					<div className="d-flex justify-content-between mb-3">
						<button className="btn btn-primary" onClick={onBack}>Back</button>
						<div>
							<button className="btn btn-primary me-2" onClick={() => handleNewGroupUserModelShow()}>Add new user</button>
							<button className="btn btn-primary btn-disabled" disabled>Bulk add users</button>
						</div>
					</div>

					<div className="table-container">
						<table {...getTableProps()} className="groups-users-table">
							<thead>
								{headerGroups.map((headerGroup) => {
									const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
									return (
										<tr key={key} {...headerProps}>
											{headerGroup.headers.map((column) => {
												const { key: colKey, ...colProps } = column.getHeaderProps();
												return (
													<th key={colKey} {...colProps}>
														{column.render("Header")}
													</th>
												);
											})}
										</tr>
									);
								})}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.length > 0 ? (
									rows.map(row => {
										prepareRow(row);
										const { key, ...rowProps } = row.getRowProps();
										return (
											<tr key={key} {...rowProps}>
												{row.cells.map(cell => {
													const { key: cellKey, ...cellProps } = cell.getCellProps();
													return (
														<td key={cellKey} {...cellProps}>
															{cell.render("Cell")}
														</td>
													);
												})}
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan={columns.length} style={{ textAlign: "center" }}>
											No users added yet.
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* Deletion conformation model */}
			<Modal show={showDeleteModal} onHide={handleDeleteModelClose} centered>
				<Modal.Body>
					Are you sure you want to delete this group?
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-primary me-3' onClick={handleDeleteModelClose}>
						Close
					</Button>
					<Button variant="btn btn-primary me-3" onClick={() => handleDelete(selectedId)}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>

			{/* status change conformation model */}
			<Modal show={showGroupStatusModal} onHide={handleGroupStatusModelClose} centered>
				<Modal.Body>
					Are you sure you want to status of this group?
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-primary me-3' onClick={handleGroupStatusModelClose}>
						Close
					</Button>
					<Button variant="btn btn-primary me-3" onClick={() => handleToggleActive(selectedId)}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>

			{/* New form model */}
			<Modal show={showNewGroupUserModal} onHide={handleNewGroupUserModelClose} centered>
				<Modal.Body>
					<form onSubmit={handleUserForm}>
						<div className="mb-3 form-box w-100">
							<input
								type="text"
								name="name"
								placeholder="User Name"
								className="form-control"
								value={editingValue.full_name || ""}
								onChange={(e) => setEditingValue({ ...editingValue, full_name: e.target.value })}
								required
							/>
						</div>
						<div className="mb-3 form-box w-100">
							<input
								type="email"
								name="email"
								placeholder="User Email"
								className="form-control"
								value={editingValue.email || ""}  // Pre-fill email value if editing
								onChange={(e) => setEditingValue({ ...editingValue, email: e.target.value })}  // Update email in the state
								required
							/>
						</div>
						<div>
							<div className="float-end">
								<Button className="me-2" onClick={handleNewGroupUserModelClose}>Cancel</Button>
								<Button type="submit">{editingValue.id ? 'Update' : 'Create'}</Button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default GroupUsersForm;
