import { useState, createContext } from "react";
import PropTypes from "prop-types";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import axios from "axios";

export const StoreContext = createContext(null);

const StoreProvider = ({ children }) => {

    const [sections, setSections] = useState('home')
    const [siteConfig, setSiteConfig] = useState(null);

    const fetchSiteConfig = async () => {
        try {
            // Make the axios request
            const responseDB = await axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: '/get-site-config',
                auth: {
                    username: process.env.REACT_APP_API_USERNAME,
                    password: process.env.REACT_APP_API_PASSWORD
                },
            });
    
            const respDBData = responseDB.data;
            const siteConfigData = respDBData.data;
    
            if (siteConfigData) {
                setSiteConfig(siteConfigData);
            } else {
                return false;
            }
        } catch (e) {
            return { success: false, error: e };
        }
    }

    const validateSession = async (sessId, userUid, pin) => {
        try {
            // Make the axios request
            const responseDB = await axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: '/get-session-data',
                auth: {
                    username: process.env.REACT_APP_API_USERNAME,
                    password: process.env.REACT_APP_API_PASSWORD
                },
                params: {
                    sess_id: sessId,
                    userUid,
                    pin: pin,
                },
            });
    
            const respDBData = responseDB.data;
            const sessDBData = respDBData.data;
    
            // Validate with Firebase
            const joinRef = doc(db, "board_session", sessId);
            const docSnap = await getDoc(joinRef);
    
            if (docSnap.exists()) {
                return { success: true, data: sessDBData };
            } else {
                return { success: false, error: "Invalid Session Key - Document doesn't exist on Firebase" };
            }
        } catch (e) {
            return { success: false, error: e };
        }
    };

    const handlePDFDownload = async (DocId, imgUrl) => {
		try {
			const response = await axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: '/download-pdf',
                responseType: 'blob',
                auth: {
                    username: process.env.REACT_APP_API_USERNAME,
                    password: process.env.REACT_APP_API_PASSWORD
                },
                params: {
                    sess_id: DocId,
                    img_url: imgUrl,
                },
			});
			const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            return { success: true, data: pdfBlob };
		} catch (error) {
			console.error('Error downloading the PDF:', error);
            return false;
		}
	};

    const handleRemoveRequest = async (DocId, vUserName) => {
        try {
            const endUserRef = doc(db, "end_users", DocId);
            getDoc(endUserRef).then(docSnap => {
                if (docSnap.exists()) {
                    let listUser = docSnap.data();

                    let approved = listUser.approved;
                    let pending = listUser.pending;
                    let denied = listUser.denied

                    let index = pending.indexOf(vUserName);

                    if (index === -1) {
                        index = denied.indexOf(vUserName)

                        if (index === -1) {
                            index = approved.indexOf(vUserName)

                            if (index !== -1) {
                                // Remove from approved
                                approved.splice(index, 1);
                                updateDoc(endUserRef, {
                                    "approved": approved
                                });
                            }
                        } else {
                            // Remove from denied
                            denied.splice(index, 1);
                            updateDoc(endUserRef, {
                                "denied": denied
                            });
                        }
                    } else {
                        // Remove from pending
                        pending.splice(index, 1);
                        updateDoc(endUserRef, {
                            "pending": pending
                        });
                    }
                }
            })
        } catch (error) {
            console.error('Error', error);
            return false;
        }
    };  
    const StoreValue = {
        siteConfig,
        fetchSiteConfig,
        sections,
        setSections,
        validateSession,
        handlePDFDownload,
        handleRemoveRequest,
    };

    return <StoreContext.Provider value={StoreValue}>{children}</StoreContext.Provider>;
};

StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StoreProvider;