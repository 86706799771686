import React from 'react'
import { Link } from 'react-router-dom';

function InstrunctionsDetails() {
    return (
        <div className="py-5 mb-5">
            <h4>Getting started</h4>
            <ol>
                <li>
                    <strong>Create an account:</strong>
                    <ul>
                        <li>Visit <Link className="links-clr" to='/login'>sushboard.com/login</Link> and create a free trial account</li>
                        <li>Sign in using your Google account</li>
                    </ul>
                </li>
                <li>
                    <strong>Download the app:</strong>
                    <ul>
                        <li>Available on the Google Play Store.</li>
                        <li>Scan the QR code at <Link className="links-clr" to='/download'>sushboard.com/download</Link></li>
                    </ul>
                </li>
            </ol>

            <h4>Creating a session</h4>
            <ol>
                <li>
                    <strong>Start a session:</strong>
                    <ul>
                        <li>Hit "Create Session"  to create a new session</li>
                        <li>Scan the QR code with the app and share the session ID or link with your students</li>
                    </ul>
                </li>
                <li>
                    <strong>Start streaming:</strong>
                    <ul>
                        <li>Mount your phone over a stand with a clear view of the page</li>
                        <li>Start the session and begin streaming</li>
                    </ul>
                </li>
                <li>
                    <strong>Join a session:</strong>
                    <ul>
                        <li>Students can use the "Join session" button or link and add their name</li>
                        <li>The student can join using the pin or tutor then grants access to join the session in guest mode</li>
                    </ul>
                </li>
            </ol>
        </div>
    )
}

export default InstrunctionsDetails