import React from "react";
import { ReactComponent as Deny } from "../../assets/deny.svg";
import { ReactComponent as Approved } from "../../assets/approved.svg";

const UserSidebarUser = ({ xUser, xtype, updateUserStatus }) => {

    return (
        <li>
            <div className={"lielemenet lielemenet-" + xtype}>
                <span>{xUser}</span>
                <span className="userlist-buttons">
                    {(xtype === 'denied' || xtype === 'pending') &&
                        <span className="button-approved">
                            <Approved onClick={() => updateUserStatus(xUser, 'approved')} />
                        </span>
                    }
                    {(xtype === 'approved' || xtype === 'pending') &&
                        <span className="button-denied">
                            <Deny onClick={() => updateUserStatus(xUser, 'denied')} />
                        </span>
                    }
                </span>
            </div>
        </li>
    )
}

export default UserSidebarUser;