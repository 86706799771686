import React, { useState, useEffect, useContext } from "react";
import { onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { saveAs } from 'file-saver';
import ReactDOM from "react-dom/client";
import { Modal } from 'react-bootstrap';
import PopupToast from "../../components/HelperComponents/PopupToast";

import { StoreContext } from "../../context/StoreProvider";
import PageView from "../PageUtilComponents/PageView";
import PageHeader from "../PageUtilComponents/PageHeader";
import ScreenLoader from "../HelperComponents/ScreenLoader";
import { ReactComponent as DownloadLogo } from "../../assets/download.svg";
import { ReactComponent as PipIcon } from "../../assets/pip_icon.svg";
import { ReactComponent as LogoutLogo } from "../../assets/logout.svg";

const pipNotSupported = 'Your browser does\'nt support PIP at present.';

const sanitizeDashFileName = (name) => {
  if (typeof name !== 'string') {
    console.error('Invalid filename provided:', name);
    return 'Whitepad-session';
  }
  return name.replace(/[^a-zA-Z0-9-_]/g, '-');
};

const Board = ({ DocId, sessName, pinVerificationStatus }) => {

  const navigate = useNavigate();
  const { handlePDFDownload, handleRemoveRequest } = useContext(StoreContext);
  const [docError, setDocError] = useState('');
  const [userError, setUserError] = useState('');
  const [tempUserName, setTempUserName] = useState(null);
  const [endUserStatus, setEndUserStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isPause, setIsPause] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const [showPT, setShowPT] = useState(false);
  const [infoMsg, setInfoMsg] = useState('');
  const [showModel, setShowModel] = useState(false);

  const [endUserName, setEndUserName] = useState(() => {
    const initialValue = localStorage.getItem("endUser");
    return initialValue || null;
  });

  const openWindow = async () => {
    if ('documentPictureInPicture' in window) {
      try {
        const dpip = await window.documentPictureInPicture.requestWindow({
          width: "350",
          height: "600",
        });
        const pipDiv = dpip.document.createElement("div");
        pipDiv.setAttribute("id", "pip-root");
        dpip.document.body.append(pipDiv);
        const pipRoot = ReactDOM.createRoot(
          dpip.document.getElementById("pip-root")
        );

        pipRoot.render(<PageView imgUrl={imgUrl} isPause={isPause} canFS={false} pipMode={true} />);

        // Function to update the pip dynamically
        const updatePip = (newUrl, updatePause) => {
          pipRoot.render(
            <PageView imgUrl={newUrl} isPause={updatePause} canFS={false} pipMode={true} />
          );
        };

        document.addEventListener("updatePip", (event) => {
          if (event.detail) {
            updatePip(event.detail.newUrl, event.detail.updatePause);
          }
        });

        //copying css
        [...document.styleSheets].forEach((styleSheet) => {
          try {
            const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
            const style = document.createElement('style');

            style.textContent = cssRules;
            dpip.document.head.appendChild(style);
          } catch (e) {
            const link = document.createElement('link');

            link.rel = 'stylesheet';
            link.type = styleSheet.type;
            link.media = styleSheet.media;
            link.href = styleSheet.href;
            dpip.document.head.appendChild(link);
          }
        });

      } catch (error) {
        if (error) {
          console.error(error);
        }
      }
    } else {
      setInfoMsg(pipNotSupported)
      setShowPT(true)
    }
  };

  const deleteUserFromLS = () => {
    handleRemoveRequest(DocId, localStorage.getItem("endUser"))
    localStorage.removeItem("endUser");
    setEndUserName(null);
  }

  const verifyNewUser = (e, vUserName) => {
    e.preventDefault();

    localStorage.setItem("endUser", vUserName)

    const endUserRef = doc(db, "end_users", DocId);
    // check if user exist
    getDoc(endUserRef).then(docSnap => {
      if (docSnap.exists()) {
        let listUser = docSnap.data();

        let approved = listUser.approved;
        let pending = listUser.pending;
        let denied = listUser.denied

        let found = false;
        let index = pending.indexOf(vUserName);

        if (index === -1) {
          index = denied.indexOf(vUserName)

          if (index === -1) {
            index = approved.indexOf(vUserName)

            if (index !== -1) {
              found = true;
            }
          } else {
            found = true;
          }
        } else {
          found = true;
        }

        if (found) {
          setUserError('Please use another username')
        } else if (pinVerificationStatus){
          approved.push(vUserName);
          setUserError('')
          setEndUserName(vUserName);
          updateDoc(endUserRef, {
            "approved": approved
          });
        }
        else{
          pending.push(vUserName);
          setUserError('')
          setEndUserName(vUserName);
          updateDoc(endUserRef, {
            "pending": pending
          });
        }
      }
    })
  };

  const generatePDF = async (sname) => {
    setShowLoader(true);
    const response = await handlePDFDownload(DocId, imgUrl);
    if (response.success) {
      setShowLoader(false);
      saveAs(response.data, sanitizeDashFileName(sname));
    } else {
      setShowLoader(false)
    }
  };

  useEffect(() => {
    if (endUserStatus) {
      let firstFlag = true;
      const unsub = onSnapshot(doc(db, "board_session", DocId), { source: 'server' }, (data) => {
        if (data.exists) {
          setDocError('');
          const docData = data.data();
          setIsPause(docData.pause);
          if (docData.page_id === 0) {
            if (!isPause || firstFlag) {
              setImgUrl(docData.data_base64);
              document.dispatchEvent(
                new CustomEvent("updatePip", { detail: { newUrl: imgUrl, updatePause: docData.pause } })
              );
              firstFlag = false;
            }
          } else {
            setImgUrl(docData.page_src);
            setIsPause(false)
            document.dispatchEvent(
              new CustomEvent("updatePip", { detail: { newUrl: imgUrl, updatePause: false } })
            );
          }
        } else {
          setDocError('Please enter a valid session key');
        }
      });

      return () => unsub();
    }

  }, [DocId, isPause, endUserStatus, imgUrl]);

  useEffect(() => {

    const unsub = onSnapshot(doc(db, "end_users", DocId), { source: 'server' }, (docData) => {
      if (docData.exists) {
        setUserError('')
        let listUser = docData.data();
        if (listUser) {
          let approved = listUser.approved;
          let index = approved.indexOf(endUserName)

          if (index !== -1) {
            setEndUserStatus(true);
          } else {
            setEndUserStatus(false);
          }
        }
      } else {
        setUserError('Error fetching user list')
      }
    });

    return () => unsub();

  }, [DocId, endUserName]);

  return (showLoader ?
    <>
      <ScreenLoader placeMiddle={true} />
      {(() => {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
        return null;
      })()}
    </>
    :
    <>
      {docError ?
        <>
          <div className="error-toast error-toast-bottom">
            <h3>{docError}</h3>
            <span className="links-clr" onClick={() => navigate('/join-session')}>Click here to re-enter session ID</span>
          </div>
        </>
        :
        <>
          {endUserStatus ?
            <>
              <ul className="fab-container">
                <li
                  key="Download"
                  className="fab-action"
                  onClick={() => generatePDF(sessName)}>
                  <DownloadLogo className="plusActionBtn" />
                  <span className="tooltip">Download</span>
                </li>

                <li
                  key="Toggle PIP"
                  className="fab-action"
                  onClick={openWindow}>
                  <PipIcon className="plusActionBtn" />
                  <span className="tooltip">Toggle PIP</span>
                </li>

                <li
                  key="Exit session"
                  className="fab-action"
                  onClick={() => {
                    setShowModel(true);
                  }}>
                    <LogoutLogo className="plusActionBtn" style={{ fill: '#240837'}} />
                  <span className="tooltip">Exit session</span>
                </li>
              </ul>
              <div className="p5 m-auto d-flex flex-column align-items-center">
                <div className={`session-card ${imgUrl ? 'mw-100' : ''}`}>
                  <PageHeader sessName={sessName} />
                  <PageView imgUrl={imgUrl} isPause={isPause} canFS={true} />
                </div>
              </div>
            </>
            :
            <>
              {userError &&
                <>
                  <div className="error-toast error-toast-bottom start-50 translate-middle error-toast-no-underline">
                    <span>{userError}</span>
                  </div>
                </>
              }

              {endUserName ?
                <>
                  <div className="error-toast top-50 start-50 translate-middle error-toast-no-underline">
                    {endUserName}, Please wait till the admin approves you request.<br /><br /> If "{endUserName}" is not you, Please click <span className="links-clr text-decoration-underline" onClick={() => deleteUserFromLS()}>here</span> to reset.
                  </div>
                </>
                :
                <div className="p5 flex-column align-items-center">
                  <h2>Join an ongoing session</h2>
                  <p>Please enter the username to join this session.</p>
                  <br />
                  <div>
                    <form onSubmit={(e) => verifyNewUser(e, tempUserName)}>
                      <input
                        type="text"
                        placeholder="Please enter your username"
                        onChange={(e) => setTempUserName(e.target.value)}
                        style={{
                          outline: (userError ? '2px solid #ff0000' : '0 !important'),
                          borderColor: '#480161',
                          minWidth: '300px'
                        }}
                        className="mb-3 form-control create-join-session-input"
                        required
                      />
                      <Button type="submit" className='btn-primary create-join-session-btn'>{pinVerificationStatus?'Join' :'Request'}</Button>
                    </form>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              }
            </>
          }
        </>
      }
      <PopupToast showPT={showPT} setShowPT={setShowPT} infoMsg={infoMsg} btnMsg='OK'/>
      <Modal show={showModel} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body><span>Are you sure you want leave this session?</span></Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-primary me-3' onClick={() => setShowModel(false)}>
            No
          </Button>
          <Button className='btn btn-primary me-3'
            onClick={() => {
              handleRemoveRequest(DocId, localStorage.getItem("endUser"))
              localStorage.removeItem("endUser");
              setEndUserName(null);
              setShowModel(false)
            }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Board;
