import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { StoreContext } from "../../context/StoreProvider";
import { AuthContext } from "../../context/AuthProvider";
import Board from "./Board";
import ScreenLoader from "../HelperComponents/ScreenLoader";

const invalidMsg = "The entered session key is invalid";

const JoinSession = () => {
    const { validateSession } = useContext(StoreContext);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams();
    const paramId = params.id;
    const [join, setJoin] = useState('');
    const [pin, setPin] = useState();
    const [enterPin, setEnterPin] = useState(false);
    const [error, setError] = useState();
    const [docId, setDocId] = useState(null);
    const [sessName, setSessName] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    const [pinVerificationStatus, setPinVerificationStatus] = useState(false);
    const [typeOfJoining, setTypeOfJoining] = useState(false)

    useEffect(() => {
        if (paramId) {
            setJoin(paramId)
            const checkUserType = async () => {
                const response = await validateSession(join, user ? user.uid : null, null);
                if (response.success) {
                    const sessData = response.data;
                    if (user) {
                        if (sessData.cust_id === user.db_id) {
                            navigate(`/presenter/${join}`);
                        }
                    }
                }
            }
            if (join) {
                checkUserType()
            }
            setTypeOfJoining(true);
        }
    }, [paramId, join, user, validateSession, navigate]);

    const validate = async () => {
        const response = await validateSession(join, user ? user.uid : null, pin);
        if (response.success) {
            const sessData = response.data;
            setSessName(sessData.session_name)
            setPinVerificationStatus(sessData.session_pin)
            setDocId(join);
            navigate(`/join-session/${join}`);
            setShowLoader(false)
        } else {
            setError(invalidMsg);
            setShowLoader(false)
        }
    };

    return (showLoader ?
        <>
            <ScreenLoader placeMiddle={true} />
            {(() => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 1500);
                return null;
            })()}
        </>
        :
        <>
            {error ?
                <>
                    <div className="error-toast top-50 start-50 translate-middle error-toast-no-underline">
                        <span>{error}</span>
                        <br />
                        <span className="links-clr" onClick={() => {
                            navigate(`/join-session`);
                            setEnterPin(false)
                            setError('');
                            setJoin('');
                            setTypeOfJoining(false)
                        }}>Close
                        </span>
                    </div>
                </>
                :
                <section className="pageModal join-section">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-12 ">

                                {docId ? <Board DocId={docId} sessName={sessName} pinVerificationStatus={pinVerificationStatus} /> :
                                    <>
                                        <h2>Join an ongoing session</h2>
                                        <p>Please enter the session id below to join a session.</p>
                                        <br />
                                        {!typeOfJoining ? <div>
                                            <input
                                                value={join}
                                                type="text"
                                                placeholder="Please enter a session key"
                                                onChange={(e) => setJoin(e.target.value)}
                                                style={{
                                                    outline: (error ? '1px solid #ff0000' : '0 !important'),
                                                    minWidth: '300px'
                                                }}
                                                className="mb-3 form-control create-join-session-input"
                                                required
                                            />
                                            <button
                                                className="btn btn-primary create-join-session-btn"
                                                onClick={() => {
                                                    if (!join.trim()) {
                                                        alert("Please enter Session key!");
                                                        return;
                                                    }
                                                    setTypeOfJoining(true);
                                                    navigate(`/join-session/${join}`);
                                                }}>
                                                Join session
                                            </button>
                                        </div> : <div>
                                            {enterPin ?
                                                <> <input
                                                    type="text"
                                                    placeholder="Enter pin"
                                                    onChange={(e) => setPin(e.target.value)}
                                                    style={{
                                                        outline: (error ? '1px solid #ff0000' : '0 !important'),
                                                        minWidth: '300px'
                                                    }}
                                                    className="mb-3 form-control create-join-session-input"
                                                    required />
                                                    <button
                                                        className="btn btn-primary create-join-session-btn"
                                                        onClick={() => { validate() }}>
                                                        Join session
                                                    </button>
                                                </> : null}
                                            {!enterPin ? <button
                                                className="btn btn-primary create-join-session-btn"
                                                onClick={() => { setEnterPin(!enterPin) }}>
                                                Join with pin
                                            </button> : null}
                                            <button
                                                className="btn btn-primary create-join-session-btn mx-2"
                                                onClick={() => { validate() }}>
                                                Join as guest
                                            </button>
                                            <br />
                                            <br />
                                        </div>}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default JoinSession;
