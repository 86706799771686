import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './scss/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContextProvider from './context/AuthProvider';
import StoreContextProvider from './context/StoreProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV !== 'production') {
  root.render(
    <React.StrictMode>
      <StoreContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </StoreContextProvider>
    </React.StrictMode>
  );
} else {
  root.render(
    <StoreContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </StoreContextProvider>
  );
}
