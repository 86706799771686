import React from "react";
import QRCode from 'qrcode.react';
import { ReactComponent as CopyLogo } from "../../assets/copy_url.svg";
import { ReactComponent as BackLogo } from "../../assets/left_sq_bracket.svg";

const QRSidebar = ({ docId, session_pin, isQRCodeOpen, ToggleQRSidebar }) => {

    const size = 200;
    const bgColor = "#eee";
    const fgColor = "#5f1587";

    const CopyToClipboard = (elementId) => {
        var text = document.getElementById(elementId).innerHTML;
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <div className={`user-sidebar ${isQRCodeOpen === true ? 'active' : ''}`}>
                <div className="usd-header">
                    <h3 className="mb-0">Session Details</h3>
                    <BackLogo className="userlist-back-btn" onClick={ToggleQRSidebar} />
                </div>
                <div className="usd-body">
                    <QRCode
                        size={size}
                        level='M'
                        includeMargin={true}
                        renderAs="svg"
                        bgColor={bgColor}
                        fgColor={fgColor}
                        value={docId}
                    />
                    <br />
                    <br />
                    <p className="p-url">
                        <span className="links-clr">User Session id : </span>
                        <span id='copysessid-sb' className="link-col">{docId}</span>
                        <span onClick={() => CopyToClipboard('copysessid-sb')}>
                            <CopyLogo className="copy-url mb-2 ms-1" />
                        </span>
                    </p>
                    <p className="p-url">
                        <span className="links-clr">Session Code : </span>
                        <span id='copyprespin-sb' className="link-col">{session_pin? session_pin : "No Pin available"}</span>
                        <span onClick={() => CopyToClipboard('copyprespin-sb')}>
                            <CopyLogo className="copy-url mb-2 ms-1" />
                        </span>
                    </p>
                    <p className="p-url">
                        <span className="links-clr">User Session link : </span>
                        <span id='copysesslink-sb' className="link-col">{window.location.origin + "/join-session/" + docId}</span>
                        <span onClick={() => CopyToClipboard('copysesslink-sb')}>
                            <CopyLogo className="copy-url mb-2 ms-1" />
                        </span>
                    </p>
                    <p className="p-url">
                        <span className="links-clr">Session details : </span>
                        <span id="copysessiondetails-sb" className="link-col" style={{ whiteSpace: 'pre-line', display: 'none'}}>
                            Session Link : {window.location.origin + "/join-session/" + docId}
                            {'\n'}
                            Session Code : {session_pin? session_pin : "No Pin available"}
                        </span>
                        <span onClick={() => CopyToClipboard('copysessiondetails-sb')}>
                            <CopyLogo className="copy-url mb-2 ms-1" />
                        </span>
                    </p>
                </div>
            </div>
            <div className={`user-sidebar-overlay ${isQRCodeOpen === true ? 'active' : ''}`} onClick={ToggleQRSidebar}></div>
        </>
    )
}

export default QRSidebar;