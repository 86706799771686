import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { Navbar, Nav, Container, Button, Modal } from 'react-bootstrap';
import { StoreContext } from '../context/StoreProvider';
import ScreenLoader from "./HelperComponents/ScreenLoader";
import header_logo from "../assets/sushboard_logo_full.svg";
import { ReactComponent as ProfileLogo } from "../assets/profile.svg";
import { ReactComponent as LogoutLogo } from "../assets/logout.svg";
import { ReactComponent as HambergerLogo } from "../assets/hamberger_menu.svg";

const Header = ({ section }) => {
    const location = useLocation();
    const isJoinSession = !(location.pathname.includes('/join-session/') || location.pathname.includes('/join-session'));
    const navigate = useNavigate();

    const { user, logOut, loading } = useContext(AuthContext);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [show, setShowLogoutModal] = useState(false);
    const handleLogoutModalClose = () => setShowLogoutModal(false);
    const handleLogoutModalShow = () => setShowLogoutModal(true);

    const handleSignOut = () => {
        logOut()
            .then(() => {
                console.log("User logged out successfully");
                handleLogoutModalClose();
                setShowMobileMenu(false);
                setShowToast(false);
                navigate("/login");
            })
            .catch((error) => console.error(error));
    };

    const { setSections } = useContext(StoreContext);
    const [links, setlinks] = useState([
        { id: 1, name: "Sushboard", url: "/", class: "home", active: `${section === 'home' ? true : false}` },
        // { id: 2, name: "About us", url: "/about-us", class: "about", active: `${section === 'about' ? true : false}` },
        { id: 3, name: "Contact us", url: "/contact-us", class: "contact", active: `${section === 'contact' ? true : false}` },
        { id: 4, name: "Instructions", url: "/instructions", class: "instructions", active: `${section === 'instructions' ? true : false}` },
        { id: 5, name: "Download App", url: "/download", class: "downloadapp", active: `${section === 'downloadapp' ? true : false}` }
    ]);
    const handleActiveLink = (name, id) => {
        setSections(links[id].class);
        let temp = [...links];
        for (let i = 0; i < temp.length; i++) {
            temp[i].active = false;
        }
        temp[id].active = true;

        setlinks(temp);
        setShowMobileMenu(false);
    }

    const handleMenuClick = () => {
        setShowMobileMenu(!showMobileMenu);
    }

    const handleHamburgerMenuClick = (link) => {
        setShowMobileMenu(false);
        navigate(`/${link}`);
    }

    useEffect(() => {
        if (user) {
            if (user.db_is_valid === 0 && user.db_is_in_trial === true) {
                setShowToast(true);
            } else {
                setShowToast(false);
            }
        }
    }, [user])


    // Render loading indicator if authentication state is still loading
    return loading ? (
        <ScreenLoader placeMiddle={false} />
    ) : (
        <>
            {/* Desktop navbar */}
            <Navbar id="navbar-desktop" fixed="top" variant="dark" className='d-none'>
                <Container>
                    <div className="main-nav-div">

                        <Nav className="me-auto">
                            {links.map((link, index) => (
                                <Link
                                    key={link.id}
                                    className={`nav-link ${link.active === true ? 'active' : ''}`}
                                    to={link.url}
                                    onClick={() => handleActiveLink(link.name, index)}
                                >
                                    {link.class === 'home' ?
                                        <img src={header_logo} className='header-logo' alt="logo" />
                                        :
                                        link.name
                                    }
                                </Link>
                            ))}

                        </Nav>
                    </div>

                    <div className='header-btn'>
                        {user ?
                            <>
                                <Button className='me-3' onClick={() => handleHamburgerMenuClick('session-manager')}>Session Manager</Button>
                                <Button className='me-3' onClick={() => handleHamburgerMenuClick('join-session')}>Join session</Button>
                                <button className='logout-btn-wrapper me-3' onClick={() => handleHamburgerMenuClick('profile')}>
                                    <ProfileLogo className="logout-btn" />
                                </button>
                                <button className='logout-btn-wrapper me-3' onClick={() => handleLogoutModalShow()}>
                                    <LogoutLogo className="logout-btn" />
                                </button>
                            </>
                            :
                            <>
                                {isJoinSession && (<Button className='me-3' onClick={() => handleHamburgerMenuClick('join-session')}>Join session</Button>)}
                                <Button className='me-3' onClick={() => handleHamburgerMenuClick('login')}>Login</Button>
                            </>
                        }
                    </div>
                </Container>
            </Navbar>

            {/* Mobile navbar */}
            <div id="navbar-mobile" className="mobile-container d-none">
                <div className="topnav">
                    <Nav className="active topnav-a" style={{ height: "56px" }}>
                        {links.map((link, index) => (
                            <React.Fragment key={link.id}>
                                {link.id === 1 &&
                                    <Link
                                        key={link.id}
                                        className={`nav-link mob-nav-link ${link.active === true ? 'active' : ''}`}
                                        to={link.url}
                                        onClick={() => handleActiveLink(link.name, index)}
                                    >
                                        {link.class === 'home' ?
                                            <img src={header_logo} className='header-logo' alt="logo" />
                                            :
                                            link.name
                                        }
                                    </Link>
                                }
                            </React.Fragment>
                        ))}
                    </Nav>
                    <Nav className='mob-nav-links d-block' style={{ opacity: (showMobileMenu ? 1 : 0), maxHeight: (showMobileMenu ? '100vh' : 0) }}>
                        {user ?
                            <>
                                <div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('session-manager')}>
                                    Session manager
                                </div>
                                <div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('join-session')}>
                                    Join session
                                </div>
                                <div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('profile')}>
                                    Profile
                                </div>
                                <div className={`nav-link mob-nav-link`} onClick={() => handleLogoutModalShow()}>
                                    Logout
                                </div>
                            </>
                            :
                            <>
                                {isJoinSession && (<div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('join-session')}>
                                    Join session
                                </div>)}
                                <div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('login')}>
                                    Login
                                </div>
                            </>
                        }
                        {links.map((link, index) => (
                            <React.Fragment key={link.id}>
                                {link.id !== 1 &&
                                    <Link
                                        key={link.id}
                                        className={`nav-link mob-nav-link ${link.active ? 'active' : ''}`}
                                        to={link.url}
                                        onClick={() => handleActiveLink(link.name, index)}
                                    >
                                        {link.name}
                                    </Link>
                                }
                            </React.Fragment>
                        ))}
                        <div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('privacy-policy')}>
                            Privacy policy
                        </div>
                        {/* <div className={`nav-link mob-nav-link`} onClick={() => handleHamburgerMenuClick('terms-and-conditions')}>
                            Terms & Conditions
                        </div> */}
                    </Nav>
                    <div className="mob-menu" onClick={() => handleMenuClick()}>
                        <HambergerLogo className="mob-menu-btn" />
                    </div>
                </div>
            </div>

            {/* confirmation modal */}
            <Modal show={show} onHide={() => handleLogoutModalClose()} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body><span>Are you sure you want to logout?</span></Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary me-3' onClick={() => handleLogoutModalClose()}>
                        Close
                    </Button>
                    <Button className='btn btn-primary me-3' onClick={() => handleSignOut()}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* trail popup */}
            {user && showToast &&
                <>
                    <div className="info-toast info-toast-top">
                        <span className='links-clr'>Beta Version!&nbsp;</span>
                        <span className="break-line">Your trial is ending in <span style={{ color: '#FF5733', fontWeight: 'bold' }}> {user.db_remaining_days} days!&nbsp;</span></span>
                        <span>pricing details will be released soon! Stay tuned!&nbsp;</span>
                        <span className="text-decoration-underline links-clr" onClick={() => setShowToast(false)}>Close</span>
                    </div>
                </>
            }

        </>
    );
};

export default Header;