import React, { useEffect, useState, useContext } from 'react'
import { db } from '../../config/firebase-config';
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";
import QRCode from 'qrcode.react';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ScreenLoader from "../HelperComponents/ScreenLoader";
import { StoreContext } from '../../context/StoreProvider';
import { ReactComponent as CopyLogo } from "../../assets/copy_url.svg";

function CreateNormalSession({ user }) {

    const navigate = useNavigate();
    const { siteConfig } = useContext(StoreContext);
    const black_img = siteConfig?.GCP_BLACK_IMG_LINK;
    const [docId, setDocId] = useState(null)
    const [sessNameField, setSessNameField] = useState('')
    const [selectedDropdownId, setSelectedDropdownId] = useState('');
    const [sessionName, setSessionName] = useState('')
    const [loading, setLoading] = useState(false);
    const [userGroup, setUserGroup] = useState([])
    const [userPastSessions, setUserPastSessions] = useState([])
    const [pin, setPin] = useState();
    const size = 200;
    const bgColor = "#eee";
    const fgColor = "#5f1587";

    const createNewSession = (e, sname, dropdownId) => {
        e.preventDefault();

        console.log(sname, dropdownId);

        const newDocRef = doc(collection(db, "board_session"));
        const data = {
            "create_on": serverTimestamp(),
            "data_base64": black_img,
            "pause": true,
            "updated_on": '',
            "admin_uid": user.uid,
            "page_id": 0,
            "page_src": ""
        }

        setDoc(newDocRef, data);
        setDoc(doc(db, "end_users", newDocRef.id), {
            "approved": [],
            "pending": [],
            "denied": [],
        });
        setDocId(newDocRef.id);

        setSessionName(sname)
        axios({
            method: 'post',
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url: '/update-session',
            auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD
            },
            params: {
                sess_id: newDocRef.id,
                sess_name: sname,
                cust_uid: user.uid,
                group_id: dropdownId,
            }
        }).then(function (response) {
            let resp_data = response.data;
            if (resp_data.status === 1) {
                setPin(resp_data.data.session_pin)
                // let data = resp_data.data;
                // console.log(data);
            } else {
                console.log(resp_data.error);
            }
        }).catch((error) => {
            if (error.response) {
                console.error('Server Error:', error.response.status);
            } else if (error.request) {
                console.error('Network Error:', error.request);
            } else {
                console.error('Error:', error.message);
            }
        });
    };

    const CopyToClipboard = (elementId) => {
        var text = document.getElementById(elementId).innerHTML;
        navigator.clipboard.writeText(text);
    }

    useEffect(() => {
        if (user) {
            const fetchGroupsData = async () => {
                setLoading(true);
                try {
                    axios({
                        method: 'get',
                        baseURL: process.env.REACT_APP_API_BASE_URL,
                        url: '/get-group-data',
                        auth: {
                            username: process.env.REACT_APP_API_USERNAME,
                            password: process.env.REACT_APP_API_PASSWORD
                        },
                        params: {
                            db_id: user.db_id
                        }
                    }).then(function (response) {
                        const respData = response.data;
                        const data = respData.data;
                        if (data && data.length > 0) {
                            setUserGroup(data);
                        }
                        setLoading(false);
                    }).catch(function (error) {
                        setLoading(false);
                        console.error('Error fetching groups data:', error);
                    })
                } catch (error) {
                    console.error("Error fetching groups data:", error);
                } finally {
                    setLoading(false);
                }
            }

            fetchGroupsData();
        }
    }, [user])

    useEffect(() => {
        if (user) {
            const fetchPastSessions = async () => {
                setLoading(true);
                try {
                    axios({
                        method: 'get',
                        baseURL: process.env.REACT_APP_API_BASE_URL,
                        url: '/get-customer-session',
                        auth: {
                            username: process.env.REACT_APP_API_USERNAME,
                            password: process.env.REACT_APP_API_PASSWORD
                        },
                        params: {
                            uid: user.uid,
                            limit: 5
                        }
                    }).then(function (response) {
                        const respData = response.data;
                        const data = respData.data;
                        if (data && data.length > 0) {
                            setUserPastSessions(data)
                        }
                        setLoading(false);
                    }).catch(function (error) {
                        setLoading(false);
                        console.error('Error fetching groups data:', error);
                    })
                } catch (error) {
                    console.error("Error fetching groups data:", error);
                } finally {
                    setLoading(false);
                }
            }
            fetchPastSessions();
        }
    }, [user]);
      
    if (loading) {
        return <ScreenLoader placeMiddle={true} />
    }

    return (
        <>
            {sessionName && docId ?
                <>
                    <div className="p5 m-auto d-flex flex-column">
                        <br />
                        <div>
                            <h4><span className="links-clr">Session:</span> {sessionName} - {docId}</h4>
                            <Button className='mb-3 btn btn-primary-reverse' style={{width : '300px'}} onClick={() => navigate(`/presenter/${docId}`)}>Join session as presenter</Button>
                            <br />
                            <h5>Use this QR code and scan using our Android app to proceed:</h5>
                            <QRCode
                                size={size}
                                level='M'
                                includeMargin={true}
                                renderAs="svg"
                                bgColor={bgColor}
                                fgColor={fgColor}
                                value={docId}
                            />
                            <br />
                            <br />
                            <br />
                            <h5>Students can join using the below session id or link:</h5>
                            <p className="p-url">
                                <span className="links-clr">Session id : </span>
                                <span id='copysessid' className="link-col">{docId}</span>
                                <span onClick={() => CopyToClipboard('copysessid')}>
                                    <CopyLogo className='svg-clr-accent-light' />
                                </span>
                            </p>
                            <p className="p-url">
                                <span className="links-clr">Session link : </span>
                                <span id='copysesslink' className="link-col">{window.location.origin + "/join-session/" + docId}</span>
                                <span onClick={() => CopyToClipboard('copysesslink')}>
                                    <CopyLogo className='svg-clr-accent-light' />
                                </span>
                            </p>
                            <p className="p-url">
                                <span className="links-clr">Session pin : </span>
                                <span id='copysesspin' className="link-col">{pin}</span>
                                <span onClick={() => CopyToClipboard('copysesspin')}>
                                    <CopyLogo className='svg-clr-accent-light' />
                                </span>
                            </p>
                        </div>
                    </div>
                </>
                :
                <>
                    <p>In normal session there is only one broadcaster who is also the admin here and multiple listners who can watch/subscribe to what the broadcaster is broadcasting.</p>
                    <p>You can also create groups which can be assigned when creating sessions, what that will do is mail all the group users that they have a session assigned to them.</p>
                    <br />
                    <h5>Create a new session:</h5>
                    <div>
                        <form onSubmit={(e) => createNewSession(e, sessNameField, selectedDropdownId)}>
                            <input
                                type="text"
                                placeholder="Please enter session name"
                                onChange={(e) => setSessNameField(e.target.value)}
                                style={{
                                    outline: '0 !important',
                                }}
                                className="mb-3 form-control create-join-session-input"
                                required
                            />
                            {(userGroup.length > 0) &&
                                <>
                                    <select
                                        id="group-dd"
                                        className="mb-3 form-control create-join-session-input"
                                        onChange={(e) => setSelectedDropdownId(e.target.value)}  // Capture the selected ID
                                    >
                                        <option className='group-dd-list' key="0" value="0">Select a Group</option>
                                        {userGroup.filter((groupData) => groupData.status === 1).map((groupData) => (
                                            <option className='group-dd-list' key={groupData.id} value={groupData.id}>{groupData.group_name}</option>
                                        ))}
                                    </select>
                                </>
                            }
                            <Button type="submit" className='create-join-session-btn'>Create Session</Button>
                        </form>
                    </div>
                    { userPastSessions && 
                        <>
                            <br />
                            <h5>Previous sessions:</h5>
                            <hr />
                            <ul className="prev-sessions">
                            {userPastSessions.map((ps, index) => (
                                <li key={index} className="prev-sessions-button" onClick={() => navigate(`/presenter/${ps.firebase_sess_id}`)}>{ps.session_name} : {ps.firebase_sess_id}</li>
                            ))}
                            </ul>
                        </>
                    }
                </>
            }
        </>
    )
}

export default CreateNormalSession

