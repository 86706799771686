import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";

const LoginWithProviders = () => {
	const { loading, user, googleLogin, UpdateUserInDB } = useContext(AuthContext);
	const navigate = useNavigate();

	// If authentication is still loading, display a loading indicator
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <span className="loading loading-dots loading-lg" role="status" aria-label="Loading">
                    Logging you in, please wait...
                </span>
            </div>
        );
    }

	// If the user is already authenticated, redirect to the home page
	if (user) {
		navigate("/");
	}

	const handleGoogleLogin = async () => {
		try {
			const result = await googleLogin();
			console.log("Successful login");
			UpdateUserInDB(result.user, "");
			navigate("/");
		} catch (error) {
			console.error("Login failed or popup closed:", error);
		}
	};
	
	// const handleFacebookLogin = async () => {
	// 	try {
	// 		const result = await facebookLogin();
	// 		console.log("Successful login");
	// 		UpdateUserInDB(result.user, "");
	// 		navigate("/");
	// 	} catch (error) {
	// 		console.error("Login failed or popup closed:", error);
	// 	}
	// };

	// const handleTwitterLogin = async () => {
	// 	try {
	// 		const result = await twitterLogin();
	// 		console.log("Successful login");
	// 		UpdateUserInDB(result.user, "");
	// 		navigate("/");
	// 	} catch (error) {
	// 		console.error("Login failed or popup closed:", error);
	// 	}
	// };
	// Render the login form
	return (
		<>
			<div className="form-control mt-6 login-submit">
				<button className="login-with-provider-btn login-google-logo" onClick={() => handleGoogleLogin()}>Login with Google</button>
				{/* <button className="login-with-provider-btn login-facebook-logo">Login with Facebook</button> */}
				{/* <button className="login-with-provider-btn login-twitter-logo">Login with Twitter</button> */}
			</div>
		</>
	);
};

export default LoginWithProviders;