import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
// import { Button } from "react-bootstrap";
import LoginWithProviders from '../components/LoginComponents/LoginWithProviders'
// import PopupToast from "../components/HelperComponents/PopupToast";

// const wrongCred = 'Please enter valid credentials to login.';

const Login = () => {
	const { loading, user } = useContext(AuthContext);
	const navigate = useNavigate();
	// const [showPT, setShowPT] = useState(false);
	// const [infoMsg, setInfoMsg] = useState('');

	// If authentication is still loading, display a loading indicator
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <span className="loading loading-dots loading-lg" role="status" aria-label="Loading">
                    Logging you in, please wait...
                </span>
            </div>
        );
    }

	// If the user is already authenticated, redirect to the home page
	if (user) {
		navigate("/");
	}

	// Handle form submission for user login
	// const handleFormSubmit = (e) => {
	// 	e.preventDefault();
	// 	const email = e.target.email.value;
	// 	const password = e.target.password.value;
	// 	loginUser(email, password)
	// 		.then((result) => {
	// 			navigate("/");
	// 		})
	// 		.catch((error) => {
	// 			const errorCode = error.code; // Get the error code
	// 			if (errorCode === "auth/invalid-credential") {
	// 				setInfoMsg(wrongCred)
	// 			} 
	// 			setShowPT(true)
	// 		});

	// 	e.target.reset();
	// };

	// Render the login form
	return (
		<>
			<section className="pageModal login-section">
				<div className="container h-100">
					<div className="row  align-items-center h-100">
						<div className="col-12 ">
							<div className="p5 m-auto d-flex flex-column align-items-center">
								<div className="card-body login-card">
									<div>
										<h3>Login</h3>
									</div>
									{/* <form onSubmit={handleFormSubmit}>
										<div className="mb-3 form-box">
											<input
												type="email"
												name="email"
												placeholder="Email"
												className="form-control"
												required
											/>
										</div>
										<div className="mb-3 form-box">
											<input
												type="password"
												name="password"
												placeholder="Password"
												className="form-control"
												required
											/>
										</div>
										<p className="text-center register-test">Forgot password? <span onClick={() => navigate('/forgot-password')} className="text-decoration-none link-col">Click here</span></p>
										<div className="form-control mt-6 login-submit">
											<Button type="submit" className="btn btn-primary form-box">Login</Button>
										</div>
									</form> */}
									<LoginWithProviders />
									{/* <div style={{ height: '5px' }}></div>
									<p className="text-center register-test mt-3">Don't have an account? <span onClick={() => navigate('/sign-up')} className="text-decoration-none link-col">Register here</span></p> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <PopupToast showPT={showPT} setShowPT={setShowPT} infoMsg={infoMsg} btnMsg='Continue'/> */}
		</>
	);
};

export default Login;