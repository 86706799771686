import React, { useContext } from 'react';
import YouTube from 'react-youtube';
import { StoreContext } from '../context/StoreProvider';
import InstrunctionsDetails from '../components/SidebarComponents/InstrunctionsDetails';

const Instructions = () => {

    const { siteConfig } = useContext(StoreContext);

    const videoId = siteConfig?.YOUTUBE_VIDEO_ID_MAIN;
    const opts = {
        playerVars: {
            autoplay: 0,         // do not auto-play the video on load
            controls: 1,         // 1Show controls
            fs: 1,               // Allow fullscreen
            iv_load_policy: 3,   // Hide annotations
            rel: 0,              // Disable related videos
            showinfo: 0,         // Hide video information
            cc_load_policy: 0,   // Hide closed captions by default
            modestbranding: 1,   // Use a modest YouTube logo
            mute: 0,             // Do not mute the video
            loop: 1,              // Enable looping
            autohide: 2,         // Automatically hide controls when playing
            // start: 30,           // Start the video at 30 seconds
            // end: 60,             // End the video at 60 seconds
        }
    };

    return (
        <>
            <section className="pageModal instructions-section">
                <div className="container h-100">
                    <div className="row h-100 align-items-center p-4">
                        <div className="col-12 pt-3">
                            <h1>Instructions</h1>
                            <p className='mb-4'>
                                Please follow the steps mentioned below or watch the video to get started.
                            </p>

                            <div className="inst-video-container">
                                <YouTube videoId={videoId} opts={opts}/>
                            </div>

                            <InstrunctionsDetails/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Instructions;