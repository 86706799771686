import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";

const Profile = () => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();

	return (
		<>
			{user ? (
				<section className="pageModal login-section">
					<div className="container h-100">
						<div className="row  align-items-center h-100">
							<div className="col-12 ">
								<div className="p5 m-auto d-flex flex-column align-items-center">
									<div className="card-body login-card">
										{user?.photoURL && (
											<img
												src={user?.photoURL}
												alt="User Profile"
												className="rounded-circle mb-4"
												style={{ width: "100px", height: "100px" }}
											/>
										)}
										<h4 className="links-clr">{user?.displayName}</h4>
										<p><strong className="links-clr">Email:</strong> {user?.email}</p>
										{user?.phoneNumber && (
											<p>
												<strong className="links-clr">Phone Number:</strong> {user?.phoneNumber}
											</p>
										)}
										{user?.metadata?.creationTime && (
											<p>
												<strong className="links-clr">Account Created:</strong> {new Date(user.metadata.creationTime).toLocaleDateString()}
											</p>
										)}
										{user?.metadata?.lastSignInTime && (
											<p>
												<strong className="links-clr">Last Sign-In:</strong> {new Date(user.metadata.lastSignInTime).toLocaleDateString()}
											</p>
										)}
										{user?.providerData?.length > 0 && (
											<div >
												<br />
												<h6><strong className="links-clr">Linked Providers:</strong></h6>
												<ul>
													{user.providerData.map((provider, index) => (
														<li key={index}>
															<strong className="links-clr">{provider.providerId}</strong> - {provider.displayName || "No name"}
														</li>
													))}
												</ul>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				navigate('/login')
			)}
		</>
	);
};

export default Profile;
